<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import {
  setDocumentDirectionPerLocale,
  setDocumentTitle,
  setDocumentLang
} from "@/util/i18n/document"
export default {
  name: "App",

  data: () => ({
    connected: true
  }),
  watch: {
      '$route' (to) {
        document.title = this.$t(to.meta.title) || 'Your Website'
      },
      '$i18n.locale' (newLocale, oldLocale) {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentLang(newLocale)
        setDocumentDirectionPerLocale(newLocale)
        setDocumentTitle(this.$t(this.$route.meta.title))
      }
    }
};
</script>
