import { APIServices } from '@/api/api';

export default {
    strict: process.env.NODE_ENV !== 'production' /* https://vuex.vuejs.org/guide/strict.html */,
    //namespaced: true,
    state: {
        user: null,
        token: localStorage.getItem('user-token') || '',
        //`'notfetched'` / `'success'` / `'failure'`
        userLoadStatus: 'notfetched',
    },
    getters: {
        user: state => state.user,
        isAuthenticated: state => !!state.token,
        token: state => state.token,
        userLoadStatus: state => state.userLoadStatus,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setUserLoadStatus(state, payload) {
            state.userLoadStatus = payload;
        },
    },
    actions: {
        autosign({ commit, getters }) {
            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                if (getters.user === null) {
                    APIServices.get('/user').then(response => {
                        // http success, call the mutator and change something in state
                        console.log('auto connection réussi !');

                        const newUser = {
                            id: response.data.id,
                            name: response.data.name,
                            email: response.data.email
                        }
                        commit('setUser', newUser)
                        
                        const token = response.data.api_key
                        localStorage.setItem('user-token', token)
                        // Add the following line:
                        APIServices.defaults.headers.common['Authorization'] = token
                        
                        commit('setToken', token)

                       // console.log("user = " + response)
                        //console.log(JSON.stringify(token))

                        resolve(response);  // Let the calling function know that http is done. You may send some data back
                    }, error => {
                        //commit('setError', error)
                        console.log('connection failed !');
                        //console.log(error.message)
                        localStorage.removeItem('user-token')
                        // http failed, let the calling function know that action did not work out
                        reject(error);
                    })
                }
            })
        },
        login({ commit, getters }, payload) {
            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                if (getters.user === null) {
                    APIServices.post('/login', {
                        email: payload.email,
                        password: payload.password
                    }).then(response => {
                        // http success, call the mutator and change something in state
                        console.log('connection réussi !');

                        const newUser = {
                            id: response.data.user.id,
                            name: response.data.user.name,
                            email: response.data.user.email
                        }
                        commit('setUser', newUser)
                        
                        const token = response.data.user.api_key
                        localStorage.setItem('user-token', token)
                        // Add the following line:
                        APIServices.defaults.headers.common['Authorization'] = token
                        commit('setToken', token)

                        //console.log("user = " + response)
                        //console.log(JSON.stringify(token))

                        resolve(response);  // Let the calling function know that http is done. You may send some data back
                    }, error => {
                        //commit('setError', error)
                        console.log('connection failed !');
                       // console.log(error.message)
                        localStorage.removeItem('user-token')
                        // http failed, let the calling function know that action did not work out
                        reject(error);
                    })
                }
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                console.log('début logout ds store')
                localStorage.removeItem('user-token') // clear your user's token from localstorage
                commit('setUser', null)
                commit('setToken', '')
                delete APIServices.defaults.headers.common['Authorization']
                resolve()
            })
        }
    },
};