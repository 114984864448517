export default {
    name: 'notifications',
    strict: process.env.NODE_ENV !== 'production' /* https://vuex.vuejs.org/guide/strict.html */,
    namespaced: true,
    state: {
      counter: 0,
      notificationsList: [],
    },
    getters: {
      notifications: state => state.notificationsList,
    },
    mutations: {
      addNotification(state, notification) {
        state.counter++;
        let newNotification = null;
        if (typeof notification === 'object') {
          newNotification = { ...notification };
          newNotification.notificationId = state.counter;
        } else {
          newNotification.notificationId = state.counter;
          newNotification.title = notification;
          newNotification.content = notification;
          newNotification.level = 'info';
        }
        state.notificationsList.push(newNotification);
      },
      removeNotification(state, notificationId) {
        let notificationIndex = state.notificationsList.findIndex(n => n.notificationId === notificationId);
        state.notificationsList.splice(notificationIndex, 1);
      },
    },
    actions: {
      addNotification({ commit }, notification) {
        commit('addNotification', notification);
      },
      removeNotification({ commit }, notificationId) {
        commit('removeNotification', notificationId);
      },
    },
  };
  