import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from './store'
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import { APIServices } from '@/api/api';

Vue.config.productionTip = false;

//Vue.use(require('vue-moment'));

const moment = require('moment')
require('moment/locale/fr')
 
Vue.use(require('vue-moment'), {
    moment
})


const token = localStorage.getItem('user-token')
if (token) {
  //console.log(token)
  APIServices.defaults.headers.common['Authorization'] = "Bearer " +token


  store.dispatch("autosign", token).then(
    () => {
      console.log("rediction vers dashboard");
    
    },
    error => {
      console.error("Got nothing from server. Prompt user to check internet connection and try again" + error);
           
    }
  );



 
}


new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
