import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);


// Translation provided by Vuetify (javascript)
import fr from 'vuetify/es5/locale/fr'

// Translation provided by Vuetify (typescript)
import en from 'vuetify/es5/locale/en'

export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: 'fr',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      }
    }
  }
});
