import Vue from 'vue';
import Vuex from 'vuex';

import UserStore from './modules/user/index.js';
import NotificationStore from './modules/notification/index.js';

Vue.use(Vuex);


export const store = new Vuex.Store({
  modules: {
    user: UserStore,
    notifications: NotificationStore,
  }
})
