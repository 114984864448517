import Vue from "vue";
import VueRouter from "vue-router";
import { store } from '../store' // your vuex store 

Vue.use(VueRouter);

function load(component) {
  // '@' is aliased to src/components
  return () =>
    import(
      /* webpackChunkName: "[request]" */ "../views/" + component + ".vue"
    );
}

const ifAuthenticated = (to, from, next) => {
  //const token = localStorage.getItem('user-token')
  //console.log('isath ' + store.getters.isAuthenticated)
  //console.log('token: ' + store.getters.token)
  //console.log(token)
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: load("Home"),
    meta: {
      title: "home"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: load("Login"),
    meta: {
      title: "login"
    },
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/register",
    name: "Register",
    component: load("Register"),
    meta: {
      title: "register"
    }
  },
  {
    path: "/account",
    name: "Account",
    component: load("Account"),
    meta: {
      title: "account"
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: "/school/:id",
    component: load("School"),
    children: [
      {
        path: "",
        name: "school",
        component: load("SchoolBoard"),
        meta: {
          title: "dashboard"
        },
        beforeEnter: ifAuthenticated
      },
      {
        path: "inscription/new",
        name: "inscriptionNew",
        component: load("SchoolInscriptionNew"),
        meta: {
          title: "Nouvelle inscription"
        }
      },
      {
        path: "inscription",
        name: "inscription",
        component: load("SchoolInscription"),
        meta: {
          title: "inscription"
        },
      },
      {
        path: "inscription-detail/:tutor_id",
        name: "inscriptionDetail",
        component: load("SchoolInscriptionDetail"),
        meta: {
          title: "Fiche d'inscription"
        }
      },
      {
        path: "paiement",
        name: "paiement",
        component: load("SchoolPaiement"),
        meta: {
          title: "paiement"
        }
      },
      {
        path: "tutor",
        name: "tutor",
        component: load("SchoolTutor"),
        meta: {
          title: "tutor"
        }
      },
      {
        path: "student",
        name: "student",
        component: load("SchoolStudent"),
        meta: {
          title: "student"
        }
      },
      {
        path: "teacher",
        name: "teacher",
        component: load("SchoolTeacher"),
        meta: {
          title: "teacher"
        }
      },
      {
        path: "class",
        name: "class",
        component: load("SchoolClass"),
        meta: {
          title: "class"
        },
      },
      {
        path: "class/:id",
        name: "classDetail",
        component: load("SchoolClassDetail"),
        meta: {
          title: "Detail Class"
        }
      },
      {
        path: "settings",
        name: "settings",
        component: load("SchoolSettings"),
        meta: {
          title: "settings"
        }
      }
    ],

  },
  {
    path: "*",
    component: load("NotFoundComponent")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  //document.title = to.meta.title;
  next();
});

export default router;
